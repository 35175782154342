.home-banner-container {
  width: 100%;
  text-align: center;
}

.home-banner-container img {
  object-fit: cover;
  width: 80vw;
}

.home-container {
  font-size: 1rem;
  max-width: 600px;
  margin: 0 auto;
}

.text {
  width: 100%;
  text-align: center;
  font-family: 'Open Sans', 'Arial Narrow', Arial, sans-serif;
  font-size: 22px;
}

.tail {
  text-align: center;
  margin: 40px auto;
}

.tail img {
  height: 60px;
}

.tail-2 {
  margin-top: 40px;
}

.home {
  font-family: 'Open Sans';
  text-align: left;
}

.text {
  margin: 20px auto;
}

.text img {
  max-width: 500px;
  display: block;
  margin: 10px auto;
}

h2 {
  text-align: center;
  font-size: 40px;
}

@media screen and (max-width: 1000px) {
  .home-container {
    max-width: 90vw;
  }

  .text img {
    max-width: 90%;
  }
}
