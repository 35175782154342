.navbar-container {
  display: flex;
  margin: 20px;
  align-items: center;
}

.navbar-emblem {
  font-size: 40px;
  font-family: 'Comic Sans';
  flex: 1;
  display: flex;
}

.navbar-emblem a {
  display: flex;
  align-items: center;
  align-content: center;
}

.navbar-emblem img {
  height: 60px;
  width: auto;
}

@media screen and (max-width: 540px) {
  .navbar-container {
    margin-bottom: 30px;
  }

  .navbar-emblem img {
    height: 40px;
  }
}
